import React from 'react';
import { Field } from 'redux-form';
import { t } from 'i18next';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import RenderField from '../../ReactComponent/RenderField';
import {
  isRequired,
  hoursValidate,
  minutesValidate,
  maxChar75,
  closingTimeValidate,
  isValidTextAreaEntry,
  isWeekend
} from '../../../utils/validator';
import { twoDigits } from '../../../utils/mask';
import {
  PageTitle,
  CategoryTitle,
  FormSummary,
  PickupTime,
  ReadyTimeSummary
} from '../css';
import { H3 } from '../../../theme/typography';
import UploadDocuments from '../../ReactComponent/UploadDocuments';
import { P, H2 } from '../../../theme/typographySitecore';
import { AMPM, IsYesOrNo } from '../../../utils/enums';
import Tooltip from '../../ReactComponent/Tooltip';

const renderAccessByTrailerControl = (accessByTrailerSummary, defaultValueObj,) => (
  <Field
    name="pickupDetails.accessByTrailer"
    component={RenderField}
    type="radio"
    value={defaultValueObj}
    label={t('AccessByTrailerSummary')}
    data={[
      {
        name: IsYesOrNo.Yes,
        value: true
      },
      {
        name: IsYesOrNo.No,
        value: false
      }]}
    defaultValue={defaultValueObj}
    validate={[isRequired]}
    required
  />
);

const renderUploadDocumentsControl = (headerStr, LTLNote, commerceNote, division) => (
  <>
    <CategoryTitle $noJustifyContent>
      <H2 field={headerStr}/>
      <Tooltip tip={t('TipUploadDocuments')} marginLeft/>
    </CategoryTitle>
    {(division === 'Freight') ?
      <P field={LTLNote}/> :
      <P field={commerceNote}/>}
    <UploadDocuments numberOfPlaceholders={4}/>
  </>
);

const renderSpecialInstructionsControl = () => (
  <Row>
    <Column $tablet={5} $mobile={12}>
      <CategoryTitle $noJustifyContent>
        {t('SpecialInstructions')}
        <Tooltip tip={t('TipSpecialInstructions')} marginLeft/>
      </CategoryTitle>
      <Field
        name="specialInstructions"
        type="textarea"
        component={RenderField}
        label={t('SpecialInstructions')}
        validate={[maxChar75, isValidTextAreaEntry]}
      />
    </Column>
  </Row>
);

const renderPickupDateControls = (division) => (
  <>
    <Row>
      <Column $tablet={4} $mobile={12}>
        <CategoryTitle>
          {t('EnterPickupDate')}
        </CategoryTitle>
        {(division === 'Freight') ?
          <Field
            name="pickupDate"
            type="date"
            className="hide-date"
            component={RenderField}
            label={t('Date')}
            required
            validate={[isRequired, isWeekend]}
          /> :
          <Field
            name="pickupDate"
            type="date"
            className="hide-date"
            component={RenderField}
            label={t('Date')}
            required
            validate={[isRequired]}
          />}
      </Column>
    </Row>
    <Row>
      <Column $tablet={6} $mobile={12}>
        <CategoryTitle $noJustifyContent>
          {t('ReadyTime')}
          <Tooltip tip={t('TipReadyTime')} marginLeft/>
        </CategoryTitle>
        <ReadyTimeSummary>
          {t('ReadyTimeText')}
        </ReadyTimeSummary>
        <PickupTime>
          <Field
            name="readyTime.hours"
            component={RenderField}
            label={t('Hours')}
            normalize={twoDigits}
            validate={[hoursValidate, isRequired]}
            className="pickup-time"
            required
          />
          <H3 className="colon"> : </H3>
          <Field
            name="readyTime.minutes"
            component={RenderField}
            label={t('Minutes')}
            normalize={twoDigits}
            validate={[minutesValidate, isRequired]}
            className="pickup-time"
            required
          />
          <Field
            name="readyTime.amOrPm"
            component={RenderField}
            label={t('AM/PM')}
            type="dropdown"
            data={[AMPM.AM, AMPM.PM]}
            validate={[isRequired]}
            className="pickup-time"
            required
          />
        </PickupTime>
        <CategoryTitle $noJustifyContent>
          {t('ClosingTime')}
          <Tooltip tip={t('TipClosingTime')} marginLeft/>
        </CategoryTitle>
        <PickupTime>
          <Field
            name="closingTime.hours"
            component={RenderField}
            label={t('Hours')}
            normalize={twoDigits}
            validate={[hoursValidate, isRequired, closingTimeValidate]}
            className="pickup-time"
            required
          />
          <H3 className="colon"> : </H3>
          <Field
            name="closingTime.minutes"
            component={RenderField}
            label={t('Minutes')}
            normalize={twoDigits}
            validate={[minutesValidate, isRequired]}
            className="pickup-time"
            required
          />
          <Field
            name="closingTime.amOrPm"
            component={RenderField}
            label={t('AM/PM')}
            type="dropdown"
            data={[AMPM.AM, AMPM.PM]}
            validate={[isRequired]}
            className="pickup-time"
            required
          />
        </PickupTime>
      </Column>
    </Row>
  </>
);

const Step4 = ({
  step,
  formData = {},
  // dispatch,
  parentFields = {}
}) => {
  const { pickupDetails } = formData;

  if (step === 3) {
    return (
      <FormSummary>
        <PageTitle>
          {parentFields.pickupDetailsPageTitle && parentFields.pickupDetailsPageTitle.value}
        </PageTitle>
        <P field={parentFields.pickupDetailsPageSummary}/>

        {renderAccessByTrailerControl(parentFields.accessByTrailerSummary.value, pickupDetails.accessByTrailer)}

        {renderPickupDateControls(formData.division)}

        {renderUploadDocumentsControl(
          parentFields.uploadDocumentsHeader,
          parentFields.uploadDocumentsLTLNote,
          parentFields.uploadDocumentsCommerceNote,
          formData.division)}

        {renderSpecialInstructionsControl()}
      </FormSummary>
    );
  }
  return null;
};

export default Step4;
