import React from 'react';
import { Field } from 'redux-form';
import { t } from 'i18next';
import Row from '../../ReactComponent/Row';
import Column from '../../ReactComponent/Column';
import RenderField from '../../ReactComponent/RenderField';
import {
  isRequired,
  hoursValidate,
  minutesValidate,
  closingTimeValidate,
  maxChar75,
  isValidTextAreaEntry,
  isWeekend
} from '../../../utils/validator';
import { twoDigits } from '../../../utils/mask';
import {
  PageTitle,
  CategoryTitle,
  BlockTitle,
  FormSummary,
  PickupTime,
  ReadyTimeSummary
} from '../css';
import { P, H3 } from '../../../theme/typography';
import { H2, P as Paragraph } from '../../../theme/typographySitecore';
import UploadDocuments from '../../ReactComponent/UploadDocuments';
import { AMPM, IsYesOrNo } from '../../../utils/enums';
import Tooltip from '../../ReactComponent/Tooltip';

const renderPickupDateControls = (parentFields, division) => (
  <>
    <Row>
      <Column $tablet={4} $mobile={12}>
        <CategoryTitle>
          { parentFields.step4Category1 && (parentFields.step4Category1.value) }
        </CategoryTitle>
        {(division === 'Freight') ?
            <Field
            name="pickupDate"
            type="date"
            className="hide-date"
            component={RenderField}
            label={t('Date')}
            required
            validate={[isRequired, isWeekend]}
          /> :
          <Field
            name="pickupDate"
            type="date"
            className="hide-date"
            component={RenderField}
            label={t('Date')}
            required
            validate={[isRequired]}
          />}

      </Column>
    </Row>
    <Row>
      <Column $tablet={6} $mobile={12}>
        <CategoryTitle $noJustifyContent>
          { parentFields.step4Category2 && parentFields.step4Category2.value }
          <Tooltip tip={t('TipReadyTime')} marginLeft/>
        </CategoryTitle>
        <ReadyTimeSummary>
          {t('ReadyTimeText')}
        </ReadyTimeSummary>
        <PickupTime>
          <Field
            name="readyTime.hours"
            component={RenderField}
            label={t('Hours')}
            normalize={twoDigits}
            validate={[hoursValidate, isRequired]}
            className="pickup-time"
            required
          />
          <H3 className="colon"> : </H3>
          <Field
            name="readyTime.minutes"
            component={RenderField}
            label={t('Minutes')}
            normalize={twoDigits}
            validate={[minutesValidate, isRequired]}
            className="pickup-time"
            required
          />
          <Field
            name="readyTime.amOrPm"
            component={RenderField}
            label={t('AM/PM')}
            type="dropdown"
            data={[AMPM.AM, AMPM.PM]}
            validate={[isRequired]}
            className="pickup-time"
            required
          />
        </PickupTime>
        <CategoryTitle $noJustifyContent>
          { parentFields.step4Category3 && parentFields.step4Category3.value }
          <Tooltip tip={t('TipClosingTime')} marginLeft/>
        </CategoryTitle>
        <PickupTime>
          <Field
            name="closingTime.hours"
            component={RenderField}
            label={t('Hours')}
            normalize={twoDigits}
            validate={[hoursValidate, isRequired, closingTimeValidate]}
            className="pickup-time"
            required
          />
          <H3 className="colon"> : </H3>
          <Field
            name="closingTime.minutes"
            component={RenderField}
            label={t('Minutes')}
            normalize={twoDigits}
            validate={[minutesValidate, isRequired]}
            className="pickup-time"
            required
          />
          <Field
            name="closingTime.amOrPm"
            component={RenderField}
            label={t('AM/PM')}
            type="dropdown"
            data={[AMPM.AM, AMPM.PM]}
            validate={[isRequired]}
            className="pickup-time"
            required
          />
        </PickupTime>
      </Column>
    </Row>
  </>
);
const renderSpecialInstructionsControl = () => (
  <Row>
    <Column $tablet={5} $mobile={12}>
      <CategoryTitle $noJustifyContent>
        {t('SpecialInstructions')}
        <Tooltip tip={t('TipSpecialInstructions')} marginLeft/>
      </CategoryTitle>
      <Field
        name="specialInstructions"
        type="textarea"
        component={RenderField}
        label={t('SpecialInstructions')}
        validate={[maxChar75, isValidTextAreaEntry]}
      />
    </Column>
  </Row>
);
const renderUploadDocumentsControl = (headerStr, LTLNote, commerceNote, division) => (
  <>
    <CategoryTitle $noJustifyContent>
      <H2 field={headerStr} />
      <Tooltip tip={t('TipUploadDocuments')} marginLeft />
    </CategoryTitle>
    {(division === 'Freight') ?
      <Paragraph field={LTLNote}/> :
      <Paragraph field={commerceNote}/>}
    <UploadDocuments numberOfPlaceholders={4} />
  </>
);

const Step4 = ({
  step,
  formData = {},
  parentFields
}) => {
  const {
    accessByTrailer,
    regularScheduledPickup
  } = formData;
  if (step === 3) {
    return (
      <FormSummary>
        <PageTitle>
          { parentFields.step4PageTitle && parentFields.step4PageTitle.value }
        </PageTitle>
        <P> { parentFields.step4Paragraph && parentFields.step4Paragraph.value }</P>
        <BlockTitle>
          { parentFields.step4BlockTitle && parentFields.step4BlockTitle.value }
        </BlockTitle>
        <Field
          name="regularScheduledPickup"
          component={RenderField}
          type="radio"
          value={regularScheduledPickup}
          data={[
            {
              name: IsYesOrNo.Yes,
              value: true
            },
            {
              name: IsYesOrNo.No,
              value: false
            }]}
          validate={[isRequired]}
        />
        <BlockTitle>
          { parentFields.step4BlockTitle2 && parentFields.step4BlockTitle2.value }
        </BlockTitle>
        <Field
          name="accessByTrailer"
          component={RenderField}
          type="radio"
          value={accessByTrailer}
          data={[
            {
              name: IsYesOrNo.Yes,
              value: true
            },
            {
              name: IsYesOrNo.No,
              value: false
            }]}
          validate={[isRequired]}
        />
        {regularScheduledPickup && !regularScheduledPickup.value && renderPickupDateControls(parentFields, formData.division)}

        {renderUploadDocumentsControl(
          parentFields.uploadDocumentsHeader,
          parentFields.uploadDocumentsLTLNote,
          parentFields.uploadDocumentsCommerceNote,
          formData.division)}

        {renderSpecialInstructionsControl()}
      </FormSummary>
    );
  }

  return null;
};

export default Step4;
